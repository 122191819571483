import React from 'react'
import carpetRoom from "../assets/Oven.png"
import { Link } from 'react-router-dom';

import { AiFillCheckCircle } from 'react-icons/ai';
import "./SteamCleanTop.css"

const WhyUsTwo = () => {
  return (
    <div className='carpet-wrapper why-us-container'>
        <div className='image-carpet'>
            <img className="carpet-room" src={carpetRoom} alt="Nice looking styled kitchen"/>
        </div> 
        <div className='carpet-description'>
          <h6>We offer a high end cleaning service on a budget price, with high quality trained cleaners. At Sprucely clean we are changing the script on acttualy delivering a quility service </h6>
            <p className='p-description'><AiFillCheckCircle size={30} color='#3AAFA9'/>Highly trained cleaners</p>
            <p className='p-description'><AiFillCheckCircle size={30} color='#3AAFA9'/>All cleaners have police check</p>
            <p className='p-description'><AiFillCheckCircle size={30} color='#3AAFA9'/>All cleaners are fully insured</p>
            <p className='p-description'><AiFillCheckCircle size={30} color='#3AAFA9'/>We have on demand cleaners at short notice</p>
            <p className='p-description'><AiFillCheckCircle size={30} color='#3AAFA9'/>5 star rateing cleaning service</p>
            <p className='p-description'><AiFillCheckCircle size={30} color='#3AAFA9'/>Accommodate anyones unique schedule</p>
            <Link style={{textDecoration: 'none', color: 'inherit', }}  to="/BookingPage"><p className='p-description end-lease-link'>BOOK NOW</p></Link>
        
      
        </div>
        
    </div>
  )
}

export default WhyUsTwo