import React, { useEffect, useRef, useState } from 'react';
import MessengerCustomerChat from 'react-messenger-customer-chat';
import Header from '../components/Header'
import ImageSlides from '../components/ImageSlides'
import Reviews from '../components/Reviews';
import Profile from '../components/Profile';
import './HomePage.css';
import { MdOutlineBathtub } from 'react-icons/md';
import { TbChecklist } from 'react-icons/tb';
import { AiOutlineDollarCircle } from 'react-icons/ai';
import { GiVacuumCleaner } from "react-icons/gi";
import TrippleIcons from '../components/TrippleIcons';
import ContactUs from '../components/ContactUs';
import { Link } from 'react-router-dom';
import ReactGA from "react-ga";
import { Helmet } from "react-helmet"
import './About.css';
import './Service.css';
import Footer from '../components/Footer';
import Team from "../assets/footerBackground.png";
import ReviewSection from '../components/ReviewSection';
import JumpingButton from '../components/JumpingButton';
import EndLeaseButton from '../components/EndLeaseButton';
import PriceCard from '../components/PriceCard';
import WorkSlides from '../components/WorkSlides';
import WhyUs from '../components/WhyUs';
import WhyUsTwo from '../components/WhyUsTwo';
import ServiceInfoSectionHome from '../components/ServiceInfoSectionHome';
import Faq from '../components/Faq';
import Tiktok from '../components/Tiktok';
import { MdOutlineWindow } from "react-icons/md";
import WindowReview from '../components/WindowReview';
import WindowReviewTwo from '../components/WindowReviewTwo';









const HomePage = () => {




  useEffect(() => {
    // Google Tag for remarketing and conversion tracking
    window.gtag('config', 'AW-11025934865');
  }, []);

   const initialTime = 3 * 60 * 60; // 3 hours in seconds
  const [remainingTime, setRemainingTime] = useState(initialTime);

  useEffect(() => {
    const interval = setInterval(() => {
      setRemainingTime((prevTime) => prevTime - 1);
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const formatTime = (timeInSeconds) => {
    const hours = Math.floor(timeInSeconds / 3600);
    const minutes = Math.floor((timeInSeconds % 3600) / 60);
    const seconds = timeInSeconds % 60;
    
    return <div className="ends-wrapper"><p className="end-p-soon">ENDS SOON</p> {hours} hours : {minutes} minutes : {seconds} seconds</div>
}



  useEffect(() => {
  ReactGA.initialize('G-ECHQXBFYLM'); 
  // Report page view
  ReactGA.pageview(window.location.pathname + window.location.search);
}, []);
useEffect(() => {
   console.log(window.location.pathname)
  })




  const servicesGridRef = useRef(null);
  const projectsMove = useRef(null);
const [isInView, setIsInView] = useState(false);
  

  useEffect(() => {
    const servicesGrid = servicesGridRef.current;
    const handleScroll = () => {
      if (servicesGrid && window.scrollY + window.innerHeight >= servicesGrid.offsetTop) {
        servicesGrid.classList.add('fade-in');
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
    
  }, 
  
  []);

useEffect(() => {
  const options = {
    rootMargin: '0px',
    threshold: 0.5,
  };

 
}, []);

const [showPopup, setShowPopup] = useState(false);




  const popupStyle = {
  position: 'fixed',
  top: '50%',
  left: '50%',
  transform: showPopup ? 'translate(-50%, -50%)' : 'translate(-50%, -150%)',
  backgroundColor: 'black',
  display: showPopup ? 'block' : 'none',
  zIndex: 999,
};


  
  

 

  const handleClose = () => {
    setShowPopup(false);
  };

  

  return (
    <div className='home__page'>
      <Helmet>
          <meta name="description" content="We offer general and end of lease cleaning service."/>
          <script async src="https://www.googletagmanager.com/gtag/js?id=AW-11025934865"></script>
        

        </Helmet>
        <Header/>
         <div className="home-button">
         <p className='custom-qoute'>Build a instant custom quote<br/>to suit your budget.</p>
          <JumpingButton/>
          <EndLeaseButton/>
        </div>
        
        <ImageSlides/>
      <div className="popup" style={popupStyle}>
        <button className="close-button" onClick={handleClose}>
            X
          </button>
          <ContactUs/>
        </div>
        
        <div className="review-section">
                <ReviewSection/>
                   <WindowReviewTwo/>
              </div>
       <div className="services-container">   
          <div className="services-grid" ref={servicesGridRef}>

          <Link style={{textDecoration: 'none', color: 'inherit', }}  to="/Turn-over-clean">
            <div className="service">
              <h3 className="service-title"> <MdOutlineBathtub size={30} color='#17252a'/> Airbnb turnover cleaning</h3>
              <p className="service-description">
                Transforming Airbnb spaces into immaculate retreats. Our skilled cleaners ensure every corner sparkles, employing eco-friendly practices for a healthier environment. Trust us for reliable, secure cleaning services that leave your property pristine for every guest.
              </p>
            </div>
          </Link>

            <Link style={{textDecoration: 'none', color: 'inherit', }}  to="/end-of-lease-cleaning-melbourne"><div className="service">
              <h3 className="service-title"><TbChecklist size={30} color='#17252a'/> Move in or move out clean</h3>
              <p className="service-description">
                Welcome to our premier end-of-lease service! We specialize in ensuring a smooth and stress-free transition at the conclusion of your lease agreement. Our dedicated team of professionals is committed to delivering top-notch cleaning, repair, and maintenance solutions, tailored to meet the specific requirements of landlords, property managers, and tenants.
              </p>
            </div></Link>
            
             <Link style={{textDecoration: 'none', color: 'inherit', }}  to="/WindowCleaning"><div className="service">
              <h3 className="service-title"><MdOutlineWindow size={30} color='#17252a'/> Window Cleaning</h3>
              <p className="service-description">
                We provide professional window cleaning services to leave your windows spotless and streak-free. Using high-quality, eco-friendly cleaning products and commercial-grade equipment, we ensure exceptional results for both residential and commercial properties. 
              </p>
            </div></Link>
            
          <Link style={{textDecoration: 'none', color: 'inherit', }}  to="/General-Cleaning-Melbourne"><div className="service">
              <h3 className="service-title"><AiOutlineDollarCircle size={30} color='#17252a'/> General home cleaning</h3>
              <p className="service-description">
              Keeping your home clean and welcoming has never been easier with our General Home Cleaning service. We understand the importance of maintaining a pristine living environment for you and your family. Our expert team is committed to providing a thorough and detailed clean, ensuring every corner of your home shines with brilliance. From deep cleaning carpets to polishing fixtures, we go above and beyond to create an inviting atmosphere that enhances your daily life.
            </p>
            </div></Link>
          </div>
    </div>
    <TrippleIcons/>
      <PriceCard/>
    
    <WhyUs/>
      
      <div className="home-next-container">
      
      
      <Reviews/>
       <Tiktok/>
      <WhyUsTwo/>
      <WorkSlides/>

    </div>
   <WindowReview/>
   
    <div className="about-container ">
            
              
              <div className="about-image-container">
                <div className="about-div-image-container">
                <img className="background-team-photo" src={Team} alt="team"/>
                </div>
                <div className="h2-about-container">
                <h2 className="heading-about">High spec clean<br/> <span className="blog-pages-p">Guarantee</span></h2>
                </div>
              </div>
          
                
                <div className="blog-home-section">
                <ServiceInfoSectionHome/>
            
                <Faq/>
                </div>

                <div className='contract-section'>
                <Profile/>
                </div>

                <MessengerCustomerChat
                pageId="262911314233913"
                appId="524942295889420"
                  />
              <div className="footer-about">
              <Footer/>
              </div>
    </div>
    </div>
  )
}

export default HomePage