import React, { useState } from 'react';
import './WindowReview.css';
import { IoMdStar } from "react-icons/io";
import icon from "../assets/iconG.png"



const reviews = [
  {
    name: 'Amber Knox.',
    review: 'Fast, friendly and amazing service. The clean for handover was excellent! Great recommendation from a friend who’d used their services previously.',
  },
  {
    name: 'David Cook.',
    review: 'Fantastic job. Would highly recommend them .',
  },
  {
    name: 'Adam Myers.',
    review: 'Excellent service from the start of the enquiry to the completion of the immaculate clean.Highly recommend, and definitely will be using again.',
  },
];

const WindowReviewTwo = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handlePrevious = () => {
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? reviews.length - 1 : prevIndex - 1));
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex === reviews.length - 1 ? 0 : prevIndex + 1));
  };

  return (
    <div className="window-review">
      <div className="review-card">
        <h3 className="reviewer-name">{reviews[currentIndex].name}</h3>
        <p className="review-text">{reviews[currentIndex].review}</p>
        
      </div>
      <div className="arrows">
        <button className="arrow-btn" onClick={handlePrevious}>&larr;</button>
        <button className="arrow-btn" onClick={handleNext}>&rarr;</button>
      </div>
      <div className="review-bottom steam-rating rate-mar-steam rev-window">
        <a
          href="https://www.google.com/search?q=sprucely+clean&rlz=1C5CHFA_enAU960AU960&oq=Sprucely+clean&gs_lcrp=EgZjaHJvbWUqCQgAEEUYOxiABDIJCAAQRRg7GIAEMgYIARBFGDsyCwgCEAAYFhgeGIsDMgoIAxAAGIsDGO8FMg0IBBAAGIsDGIAEGKIEMgoIBRAAGIsDGO8FMgYIBhBFGD0yBggHEEUYPdIBCDM1OTBqMWo3qAIAsAIA&sourceid=chrome&ie=UTF-8#mpd=~11666644124658794508/customers/reviews"
          target="_blank"
          rel="noopener noreferrer"
          className="google-link"
        >
          <div className="review-content-bottom">
            <IoMdStar size={30} color="rgb(58, 175, 169)" />
            <IoMdStar size={30} color="rgb(58, 175, 169)" />
            <IoMdStar size={30} color="rgb(58, 175, 169)" />
            <IoMdStar size={30} color="rgb(58, 175, 169)" />
            <IoMdStar size={30} color="rgb(58, 175, 169)" />

            <img className="gimage" src={icon} alt="logo for customer rating" />
          </div>
        </a>
      </div>

          </div>
  );
};

export default WindowReviewTwo;
