import React from 'react'
import carpetRoom from "../assets/whyUsPic.png"
import "./SteamCleanTop.css";
import rating from "../assets/ratingPic.png"
import { AiFillCheckCircle } from 'react-icons/ai';
import { Link } from 'react-router-dom';

const GeneralCleanTwo = () => {
  return (
    
    <div className='carpet-wrapper carpet-wrapper-two'>
        <div className='image-carpet'>
            <img className="carpet-room" src={carpetRoom} alt="Carpet that has been steamed clean"/>
        </div> 
       <div className='carpet-description'>
          <h2 className='rec-head head-window-top'>Fliping the switch on unreliable trades, we strive to make our mom 's proud.</h2>
          <p className='p-description'><AiFillCheckCircle size={30} color='#3AAFA9'/>Unlike others we dont charge hidden hourly rates onsite</p>
          <p className='p-description'><AiFillCheckCircle size={30} color='#3AAFA9'/>No contracting to knucklehead contractors</p>
          <p className='p-description'><AiFillCheckCircle size={30} color='#3AAFA9'/>Police checked safe cleaners.</p>
          <p className='p-description'><AiFillCheckCircle size={30} color='#3AAFA9'/>You book and we show up, usually 10mins early</p>
          <p className='p-description'><AiFillCheckCircle size={30} color='#3AAFA9'/>Trained cleaners with sufficient equipment</p>
          <p className='p-description'><AiFillCheckCircle size={30} color='#3AAFA9'/>We actauly enjoy what we do and deliver a fun service</p>
          <p className='p-description'><AiFillCheckCircle size={30} color='#3AAFA9'/>Consistently rated 5 stars by our satisfied customers</p>
          <p className='p-description custom-p'><span className='finger'>👇</span> Get a custom quote here and enjoy an additional 10% discount <span className='finger'>👇</span></p>
          <Link style={{textDecoration: 'none', color: 'inherit', }}  to="/General-Cleaning-Melbourne-quote"><button className='p-description end-lease-link'>BOOK NOW</button></Link>
    
    <img className="rating-img" src={rating} alt="rating symbol"/>
</div>

    </div>
  )
}

export default GeneralCleanTwo