import React from 'react'
import carpetRoom from "../assets/showerbefore.png"
import "./SteamCleanTop.css";
import { Link } from 'react-router-dom';


const EndLeaseFour = () => {
  return (
    
    <div className='carpet-wrapper carpet-wrapper-two'>
        <div className='image-carpet'>
            <img className="carpet-room" src={carpetRoom} alt="Carpet that has been steamed clean"/>
        </div> 
        <div className='carpet-description third-end-wrap'>
         <h2 className='not-sure rec-head'>We get it right first time and avoid call backs. </h2>
          <p className='p-description'>Sprucely Clean: Proficient Move Out Cleaning Specialists with Meticulous Attention to Detail for a Immaculate Property</p>
          <p className='p-description'>Our team of skilled move out cleaners takes pride in their meticulous attention to detail, ensuring that every corner and crevice receives a thorough cleaning to meet high standards. We recognize the uniqueness of each property and invest the time to comprehend your specific cleaning needs, delivering a service that surpasses expectations.</p>
          <p className='p-description'>From bedrooms and bathrooms to living spaces, kitchens, windows, carpets, walls, and even balconies or garages, our team possesses the expertise and tools to confront any cleaning challenge. We exclusively utilize premium cleaning products and methods to guarantee deep and comprehensive cleanliness, leaving your property looking and feeling refreshed and revitalized.</p>
          <p className='p-description'>At Sprucely Clean, we are dedicated to delivering a hassle-free and effective service, allowing you to concentrate on the next phase of your journey. Entrust us with your end of lease cleaning requirements, confident that you are in capable hands.</p>


            <Link style={{textDecoration: 'none', color: 'inherit', }}  to="/end-of-lease-cleaning"><button className='p-description end-lease-link end-three'>LIVE BOOKING AND PRICE</button></Link>
       
           
        
        </div>
        
    </div>
  )
}

export default EndLeaseFour