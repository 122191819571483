import React from 'react'
import carpetRoom from "../assets/whyUsPic.png"
import { AiFillCheckCircle } from 'react-icons/ai';
import "./SteamCleanTop.css";
import logoCraig from "../assets/craigLogo.png"

const EndLeaseTwo = () => {
  return (
    
    <div className='carpet-wrapper carpet-wrapper-two'>
        <div className='image-carpet'>
            <img className="carpet-room" src={carpetRoom} alt="Carpet that has been steamed clean"/>
        </div> 
        <div className='carpet-description'>
         <h2 className='not-sure rec-head'>We love cleaning.</h2>
            <p className='p-description'>End of lease bond cleaning, a meticulous deep clean before vacating a rental property, is crucial for reclaiming your bond deposit. At Sprucely Clean, we specialize in this meticulous task, ensuring your complete satisfaction and a hassle-free move.</p>
            <p className='p-description'>Our professional bond cleaners in Melbourne are dedicated to securing your deposit in full. With a commitment to excellence, our fully insured and trained teams deliver top-tier end of lease cleaning services tailored to your needs.</p>
            <p className='p-description'>Exiting a rental property can be overwhelming, especially when it comes to the final clean. Landlords and agents have high standards, but Sprucely Clean rises to the challenge. Our expert lease cleaners meticulously attend to every detail, employing advanced equipment and premium products to leave no corner untouched. From walls to carpets, windows to bathrooms, we guarantee a pristine result.</p>
            <p className='p-description'>At Sprucely Clean, we recognize that each property is unique, offering bespoke cleaning solutions to fit your requirements and budget. Whether it's a standard end of lease clean or a comprehensive deep clean, we've got you covered.</p>
            <p className='p-description'>no pre-inspection needed for final quote</p>
            <p className='p-description'>Count on our exceptional customer service and a satisfaction guarantee. Don't risk losing your bond over overlooked details. Contact Sprucely Clean today, and let us handle the cleaning while you focus on your move. You book. We clean. You get your bond back.</p>
            <p className='p-description-partners'>Top contractor for</p>
            <img className="logo-agent" src={logoCraig} alt="logo of realestate agent"/>
        
        </div>
    </div>
  )
}

export default EndLeaseTwo