import React, { useState, useEffect } from 'react';
import carpetRoom from "../assets/CarpetRoom.png"
import rating from "../assets/ratingPic.png"
import { AiFillCheckCircle } from 'react-icons/ai';
import "./SteamCleanTop.css"
import { Link } from 'react-router-dom';

const EndOfLeaseTop = () => {


  const [iconSize, setIconSize] = useState(30);

  useEffect(() => {
    const handleResize = () => {
      // Check screen width and set icon size accordingly
      if (window.innerWidth <= 500) {
        setIconSize(20); // Set size for smaller screens
      } else {
        setIconSize(30); // Set default size for larger screens
      }
    };

    // Call handleResize on initial load
    handleResize();

    // Add event listener for window resize
    window.addEventListener('resize', handleResize);

    // Remove event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []); // Empty dependency array ensures that this effect runs only once on initia
  return (
    <div className='carpet-wrapper'>
        <div className='image-carpet'>
            <img className="carpet-room" src={carpetRoom} alt="Nice looking styled room with carpet"/>
        </div> 
        <div className='carpet-description'>
         <h2 className='rec-head'>Reclaim your bond back hassle-free.</h2>
            <p className='p-description'><AiFillCheckCircle size={iconSize} color='#3AAFA9'/>Don't stress about unhappy agents and reclean fees</p>
            <p className='p-description'><AiFillCheckCircle size={iconSize} color='#3AAFA9'/>Don't stress about no shows and incompetent cleaners.</p>
            <p className='p-description'><AiFillCheckCircle size={iconSize} color='#3AAFA9'/>Full end of lease cleaning to agents requirements</p>
            <p className='p-description'><AiFillCheckCircle size={iconSize} color='#3AAFA9'/>Dedicated support from booking to completion</p>
            <p className='p-description'><AiFillCheckCircle size={iconSize} color='#3AAFA9'/>End of lease bond cleaning from $247</p>
            <p className='p-description'><AiFillCheckCircle size={iconSize} color='#3AAFA9'/>End of lease cleaning across Melbourne</p>
            <p className='p-description'><AiFillCheckCircle size={iconSize} color='#3AAFA9'/>Constant 5 star reviews</p>
            <p className='p-description'><AiFillCheckCircle size={iconSize} color='#3AAFA9'/>We show up ontime everytime</p>
            <p className='p-description custom-p'><span className='finger'>👇</span> Custom quote here + 10% dicount <span className='finger'>👇</span></p>
            <Link style={{textDecoration: 'none', color: 'inherit', }}  to="/end-of-lease-cleaning"><button className='p-description end-lease-link'>LIVE BOOKING AND PRICE</button></Link>
            
        <img className="rating-img" src={rating} alt="rating symbol"/>
        </div>
        
    </div>
  )
}

export default EndOfLeaseTop