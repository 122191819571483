import React from 'react';
import './PriceCard.css'; // Make sure to create this CSS file
import { Link } from 'react-router-dom';

const PriceCard = () => {
  
    // Function to handle conversion tracking
  const handleConversion = (url) => {
    var callback = function () {
      if (typeof(url) !== 'undefined') {
        window.location = url;
      }
    };

    // Trigger the conversion event
    window.gtag('event', 'conversion', {
      'send_to': 'AW-11025934865/7d7dCN732ZoYEJHUyYkp',
      'event_callback': callback
    });
  };
    
const steamClean = [
    'Residential window cleaning',
    'Commercial window',
    'Quick and easy Estimate',
    'Full insurance cover',
    'Single of multi level property'
  ];

  const endOfLease = [
    'End of lease cleaning',
    '100% bond back guarantee',
    'Best service and best price',
    'Fixed price end of lease clean',
    'Agent not happy we come back'
  ];

  const genClean = [
    'Full home cleaning',
    'General or spring cleaning',
    'Best service and best price',
    'Fixed price general cleaning',
    'No hidden fees'
  ];

  const regClean = [
    'General cleaning',
    'Laundry service',
    'Automated bookings',
    'Fixed price cleaning',
    'No hidden costs'
  ];


 



  return (
    
    <div className="price-card">
   

      <div className="price-box price-box-one box-color-change">
        <h2 className="h2-book">Window cleaning</h2>
        <h3 className="book-des">Starting at</h3>
        <p className="book-des dollar">$149</p>

        <div className="tick-list">
        {steamClean.map((steamClean, index) => (
          <p key={index}>{steamClean}</p>
        ))}
      </div>

        <h4 className="book-des-h4">General or commercial window cleaning.</h4>
        <Link style={{textDecoration: 'none', color: 'inherit', }}  to="/WindowCleaning" onClick={() => handleConversion('/WindowCleaning')} ><button className='book-button'>Build quote</button></Link>
      </div>

      <div className="price-box price-box-one">
        <h2 className="h2-book">Airbnb turn over</h2>
        <h3 className="book-des">Starting at</h3>
        <p className="book-des dollar">$135</p>

        <div className="tick-list">
        {regClean.map((regClean, index) => (
          <p key={index}>{regClean}</p>
        ))}
      </div>

        <h4 className="book-des-h4">Budget friendly turnover cleaning service.</h4>
        <Link style={{textDecoration: 'none', color: 'inherit', }}  to="/Turn-over-clean" onClick={() => handleConversion('/Turn-over-clean')} ><button className='book-button'>Build quote</button></Link>
      </div>

        <div className="price-box price-box-three box-color-change">
                <h2 className="h2-book">Move Out/In Clean</h2>
                <h3 className="book-des">Starting at</h3>
                <p className="book-des dollar">$247</p>
                
                <div className="tick-list">
                {endOfLease.map((endOfLease, index) => (
                  <p key={index}>{endOfLease}</p>
                ))}
              </div>

                <h4 className="book-des-h4">100% bond return cleaning service near you, fixed price cleaning.</h4>
                <Link style={{textDecoration: 'none', color: 'inherit', }}  to="/end-of-lease-cleaning" onClick={() => handleConversion('/end-of-lease-cleaning')}><button className='book-button'>Build quote</button></Link>
                
              </div>

              <div className="price-box price-box-three box-color-change">
                <h2 className="h2-book">General Clean</h2>
                <h3 className="book-des">Starting at</h3>
                <p className="book-des dollar">$110</p>
                
                <div className="tick-list">
                {genClean.map((genClean, index) => (
                  <p key={index}>{genClean}</p>
                ))}
              </div>

                <h4 className="book-des-h4">General, deep and spring home cleaning</h4>
                <Link style={{textDecoration: 'none', color: 'inherit', }}  to="/General-Cleaning-Melbourne-quote" onClick={() => handleConversion('/General-Cleaning-Melbourne-quote')}><button className='book-button'>Build quote</button></Link>
                
              </div>

          
    </div>
   
   
  );
};

export default PriceCard;
