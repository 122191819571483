import React, { useState, useRef, useEffect, useCallback, useMemo} from "react";
import ReactGA from 'react-ga';
import { Helmet } from "react-helmet"
import emailjs from "@emailjs/browser";
import "./BookingClean.css";
import HeaderSteam from "../components/Header";
import { BsListCheck } from 'react-icons/bs';
import { BsFileEarmarkPerson } from 'react-icons/bs';
import { BiLocationPlus } from 'react-icons/bi';
import { FaHome } from "react-icons/fa";
import Footer from "../components/Footer";
import TrippleIcons from "../components/TrippleIcons";
import rating from "../assets/ratingPic.png"
import Profile from "../components/Profile";
import { useNavigate } from "react-router-dom";
import icon from "../assets/iconG.png"
import { IoMdStar } from "react-icons/io";
import { MdOutlineWindow } from "react-icons/md";
import { FiSquare } from "react-icons/fi";
import { MdOutlineStorefront } from "react-icons/md";


// Define a component called Result to display a confirmation message

const Result = () => {
  return (
    <p className="result-msg">Our team will contact you shortly</p>
  );
};


// Define a component called PriceSection to calculate and display the price quote
const PriceSection = ({ 
  selectedBedroom, 
  selectedBathroom, 
  selectedKitchen, 
  selectedCleanliness, 
  selectedSteamClean, 
  selectedHallWay,
  selectedWindowClean, 
  selectedBalconyClean, 
  selectedStairs,
  selectedRugs,
  handleDiscountClick,
  setFinalPrice }) => {
  

  //By wrapping the object initializations in useMemo with an empty dependency array, you ensure that these objects are only created once and won't cause the useCallback dependencies to change on every render.

  // Define prices for steam cleaning, window cleaning, and balcony cleaning
  const steamCleanPrices = useMemo(() => {
  return {
    
    "1_bedroom + living": 149,
    "2_bedroom + living": 195,
    "3_bedroom + living": 250,
    "4_bedroom + living": 295,
    "5_bedroom + living": 345,
    "6_bedroom + living": 400,

  };
}, []);


 const hallWaySteam = useMemo(() => {
  return {
    "single_level": 0,
    "multi_level": 50,
    

  };
}, []);

// Wrap the initialization of disinfectCarpets in useMemo
const disinfectCarpets = useMemo(() => {
  return {
    "Shop front window": 149,
    
   
  };
}, []);

// Wrap the initialization of balconyCleanPrices in useMemo
const balconyCleanPrices = useMemo(() => {
  return {
    "Small Windows": 0,
    "Standard size": 0,
    "Large, floor to ceiling": 0,
  };
}, []);

const stairsCleanPrices = useMemo(() => {
    return {
      "Outside_only": 0,
      "Inside_only": 0,
      "Inside_&_Outside": 50,
    };
  }, []);

  const rugCleanPrices = useMemo(() => {
    return {
      "2 to 4 screens": 20,
      "4 to 6 screens": 30,
      "6 plus screens": 80,
    };
  }, []);
  
  
   // Function to calculate the final price based on selected options
   const calculatePrice = useCallback(() => {
    

    // Define a list of price options for different combinations
    const priceOptions = [
      { bedrooms:  1, bathrooms: 1, kitchens: 1, price: 250 },
      { bedrooms: 1, bathrooms: 2, kitchens: 1, price: 320 },
      { bedrooms: 2, bathrooms: 1, kitchens: 1, price: 290 },
      { bedrooms: 2, bathrooms: 2, kitchens: 1, price: 350 },
      { bedrooms: 3, bathrooms: 1, kitchens: 1, price: 350 },
      { bedrooms: 3, bathrooms: 2, kitchens: 1, price: 400 },
      { bedrooms: 4, bathrooms: 2, kitchens: 1, price: 450 },
    ];

    // Find the selected option based on the number of bedrooms, bathrooms, and kitchens
    const selectedOption = priceOptions.find(option =>
      option.bedrooms === selectedBedroom &&
      option.bathrooms === selectedBathroom &&
      option.kitchens === selectedKitchen
    );

    // Calculate prices for additional services
      const steamCleanPrice = steamCleanPrices[selectedSteamClean] || 0;
      const hallWay = hallWaySteam[selectedHallWay] || 0;
      const windowCleanPrice = disinfectCarpets[selectedWindowClean] || 0;
      const balconyCleanPrice = balconyCleanPrices[selectedBalconyClean] || 0;
      const stairsCleanPrice = stairsCleanPrices[selectedStairs] || 0;
      const rugCleanPrice = rugCleanPrices[selectedRugs] || 0;

  
  
    // Calculate the final price
    let finalPrice = selectedOption ? selectedOption.price : 0;

   

    if (selectedCleanliness === "Average_condition") {
      finalPrice += 20;
    } else if (selectedCleanliness === "Poor_condition") {
      finalPrice += 50;
    }

    finalPrice += steamCleanPrice;
    finalPrice += hallWay;
    finalPrice += windowCleanPrice;
    finalPrice += balconyCleanPrice;
    finalPrice += stairsCleanPrice;
    finalPrice += rugCleanPrice;

    return finalPrice;
  }, [steamCleanPrices,  
    selectedSteamClean, 
    hallWaySteam, 
    selectedHallWay, 
    disinfectCarpets, 
    selectedWindowClean, 
    balconyCleanPrices, 
    selectedBalconyClean, 
    stairsCleanPrices, 
    selectedStairs, 
    selectedCleanliness, 
    selectedBedroom, 
    selectedBathroom, 
    selectedKitchen,
    rugCleanPrices,
    selectedRugs,
  ]);

   // Calculate the price
    const price = calculatePrice();

   // Check if the user has selected any options
   const hasSelectedOptions = selectedBedroom !== 0 && selectedBathroom !== 0 && selectedKitchen !== 0;
   

   // Define cleaning checklists for different areas
   const bedroomCleaningChecklist = [
      "Dust all surfaces",
      "Vacuum and mop floors",
      "Clean mirrors + wardrobe",
      "Clean internal windows",
      "Clean skirtings",
      "Spot clean walls",
      "Internal windows",
    ];

    const bathroomCleaningChecklist = [
      "Clean toilet and sink.",
      "Clean shower and bath",
      "Wipe down mirrors and any glass",
      "Dust shelves and counters",
      "Mop or sweep the floor",
      "Clean inside cabinets",
      "Spot clean walls",
      "Internal windows",
    ];

    const kitchenCleaningChecklist = [
      "Clean countertop and sink",
      "Clean stovetop and oven",
      "Clean range hood",
      "Wipe down cabinets and drawers",
      "Clean inside cabinets",
      "Mop or sweep the floors",
      "Clean skirtings",
      "Mop or sweep the floor",
      "Spot clean walls",
      "Internal windows",
    ];

    const livingCleaningChecklist = [
      "Dust all surfaces",
      "Vacuum, sweep or mop floors",
      "Internal windows",
      "Clean skirtings",
      "Spot clean walls",
    ];

    

  // useEffect to pass onto hidden input for email.js

  useEffect(() => {
    const price = calculatePrice();

    // Update the finalPrice in the parent component
   setFinalPrice(price);
}, [
  selectedBedroom,
  selectedBathroom,
  selectedKitchen,
  selectedCleanliness,
  selectedSteamClean,
  selectedHallWay,
  selectedWindowClean,
  selectedBalconyClean,
  setFinalPrice,
  calculatePrice, // Include calculatePrice in the dependency array
]);


// Render the price section with selected options and checklists
  return (
    <div className="price-section">
      <h4 className="price-heading">Quote Price:</h4>
      <div className="white-box">
       <p className="selected-option agent-clean"><BsListCheck color="#3AAFA9" size={25}/><span className="agent-heading agent-color"> Window Cleaning</span></p>
              
        <p className="selected-option price">Package details:</p>
        {selectedSteamClean && (
        <p className="selected-option"><span className="select-p-option">Size of home:</span> {selectedSteamClean.replace("_", " ")}</p>
      )}
      {selectedWindowClean && (
          <p className="selected-option"><span className="select-p-option">Shop front window clean:</span> {selectedWindowClean.replace("_", " ",)}</p>
        )}
        {selectedBalconyClean && (
          <p className="selected-option"><span className="select-p-option">Size of windows:</span> {selectedBalconyClean.replace("_", " ")}</p>
        )}

         {selectedStairs && (
        <p className="selected-option">
          <span className="select-p-option">Cleaning inside + outside:</span> {selectedStairs.replace("_", " ")}
        </p>
      )}

      {selectedRugs && (
        <p className="selected-option">
          <span className="select-p-option">Rug Steam Cleaning:</span> {selectedRugs.replace("_", " ")}
        </p>
      )}

      {selectedHallWay && (
        <p className="selected-option">
          <span className="select-p-option">Single or multi level:</span> {selectedHallWay.replace("_", " ")}
        </p>
      )}
        
        <hr />
        {hasSelectedOptions ? (
          <p className="price"> TOTAL ESTIMATE: <span className="color-price">${price}</span></p>
        ) : (
          <p className="price">Please select options</p>
        )}

         {/*Discount render*/}
        

        <p className="price book-later">Send booking request and our team will contact you shortly to confirm<br/> booking.</p>
      </div>
      
    </div>
  );
};

// Define the BookingClean component
const SteamForm = () => {
const navigate = useNavigate();

  

  const [discountApplied, setDiscountApplied] = useState(false);

  const handleDiscountClick = () => {
    // Calculate the discounted price (10% off)
    const discountedPrice = finalPrice * 0.9;

    // Set the final price with the discount applied
    setFinalPrice(discountedPrice);
    setDiscountApplied(true);
  };
  

   useEffect(() => {
    // Google Tag for remarketing and conversion tracking
    window.gtag('config', 'AW-11025934865');
  }, []);

  useEffect(() => {
  ReactGA.initialize('G-ECHQXBFYLM'); 
  // Report page view
  ReactGA.pageview(window.location.pathname + window.location.search);
}, []);
useEffect(() => {
   console.log(window.location.pathname)
  })


  const [finalPrice, setFinalPrice] = useState(0);
  //State variable for discount
 



  useEffect(() => {
  window.scrollTo(0, 0); // Scroll to the top of the page
}, []);



  const FormSubmission = () => {
    ReactGA.event({
      category: 'Button',
      action: 'Form submission button clicked'
    });
  };

  // Define state variables for form inputs and result display

  const [result, showResult] = useState(false);
  const [selectedDateTime, setSelectedDateTime] = useState("");
  const [selectedBedroom, setSelectedBedroom] = useState("1");
  const [selectedBathroom, setSelectedBathroom] = useState("1");
  const [selectedKitchen, setSelectedKitchen] = useState("1");
  const [selectedCleanliness, setSelectedCleanliness] = useState("slightly_dirty");
  const [selectedSteamClean, setSelectedSteamClean] = useState("");
  const [selectedHallWay, setSelectedHallWay] = useState("");
  const [selectedWindowClean, setSelectedWindowClean] = useState("");
  const [selectedBalconyClean, setSelectedBalconyClean] = useState("");
  const [selectedStairs, setSelectedStairs] = useState("");
  const [selectedRugs, setSelectedRugs] = useState("");
  

  const form = useRef(null);

   // Function to send an email and display a confirmation message

  const sendEmail = (e) => {
    e.preventDefault();

    const selectedExtras = {
          selectedSteamClean,
          selectedHallWay,
          selectedWindowClean,
          selectedBalconyClean,
          selectedStairs,
          selectedRugs,

        };

        // Include the selected extras in the email template
        const emailParams = {
          // Other email parameters...
          selectedExtras: JSON.stringify(selectedExtras),
        };

    
    emailjs
      .sendForm("service_k4aiwmr", "template_wphtiyi", form.current, "Fh0Y9YM-DloiKhGif", emailParams)
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );

    e.target.reset();
    showResult(true);

    navigate("/Thanks");
  };


  // Render the BookingSteam component

  return (
    <div>
    <Helmet>
          <meta name="description" content="We offer general and end of lease cleaning service."/>
          <script async src="https://www.googletagmanager.com/gtag/js?id=AW-11025934865"></script>
        </Helmet>
      <HeaderSteam/>
    
  
      
      <TrippleIcons/>
      <div className="form-clean-book form-wrapper-steam">
        
        <h2 className="clean-heading">Book your window clean</h2>
        <p className="clean-heading-p">"High quality, low cost"</p>
        <h2 className="clean-h2">*BOOK NOW PAY LATER</h2>
        <img className="rating-badge" src={rating} alt="rating badge"/>
        <form className="form-clean" ref={form} onSubmit={sendEmail}>

          <div className="form-one">
          <p className="price_info"></p>
            <div className="form-container-clean">
            <div className="icon-heading-container">
                    <BsFileEarmarkPerson size={25} />
                    <h4 className="clean-h4">WHO YOU ARE</h4>
                  </div>
           
              <p className="clean-p">This information will be used to contact<br /> you about your service.</p>

              <input className="input-contact-clean" type="text" name="user_name" placeholder="Name" required />
              <input className="input-contact-clean" type="email" name="user_email" placeholder="Email" required />
              <input className="input-contact-clean" type="text" name="user_mobile" placeholder="Mobile" required />

              <div className="address-section">
              <div className="icon-heading-container">
                    <BiLocationPlus size={25} />
                    <h4 className="clean-h4">ADDRESS</h4>
                  </div>
             
                <p className="clean-p">Where would you like us to clean?</p>
                <textarea className="input-contact-clean" type="text" name="address" placeholder="Address" required />
                <textarea className="input-contact-clean" type="text" name="suburb" placeholder="Suburb" required />
              </div>

               <div className="icon-heading-container free-pre-vac-container">
                    <MdOutlineStorefront size={25} />
                    <h4 className="clean-h4">Shop front window cleaning</h4>
                  </div>

                <select
                  className="input-contact-clean input-select-width"
                  name="shop_window_clean" // Change the name attribute to something appropriate
                  onChange={(e) => setSelectedWindowClean(e.target.value)}
                 
                    >
                  <option value="">Select</option>
                  <option value="Shop front window">Starting from $149</option>
                </select>
              

              <div className="icon-heading-container">
                    <FaHome size={25} />
                    <h4 className="clean-h4">Residential</h4>
                  </div>

                <select className="input-contact-clean input-select-width" name="steam_clean" onChange={(e) => setSelectedSteamClean(e.target.value)} >
                  <option value="">Select size of home?</option>
                  <option value="1_bedroom + living">1 Bedroom + living $149</option>
                  <option value="2_bedroom + living">2 Bedroom + living $195</option>
                  <option value="3_bedroom + living">3 Bedroom + living $250</option>
                  <option value="4_bedroom + living">4 Bedroom + living $295</option>
                  <option value="5_bedroom + living">5 Bedroom + living $345</option>
                  <option value="6_bedroom + living">6 Bedroom + living $400</option>
                </select>



                <select className="input-contact-clean input-select-width" name="hallway" onChange={(e) => setSelectedHallWay(e.target.value)} >
                  <option value="">Single storey or multi level?</option>
                  <option value="single_level">Single level</option>
                  <option value="multi_level">2 to 3 storey $50</option>
                 
                </select>

              
                <select
                    className="input-contact-clean input-select-width"
                    name="stairs_clean"
                    onChange={(e) => setSelectedStairs(e.target.value)}
                    
                  >
                    <option value="">What side of window do you want cleaned?</option>
                    <option value="Outside_only">Outside only</option>
                    <option value="Inside_only">Indside only</option>
                    <option value="Inside_&_Outside">Inside & outside $50</option>
                  </select>


                  <div className="icon-heading-container">
                    <MdOutlineWindow size={25} />
                    <h4 className="clean-h4">Size of windows</h4>
      
                  </div>

                 <select
                  className="input-contact-clean input-select-width"
                  name="sofa_clean" // Change the name attribute to something appropriate
                  onChange={(e) => setSelectedBalconyClean(e.target.value)}
              
                    >
                  <option value="">Select window size</option>
                  <option value="Small Windows">Small</option>
                  <option value="Standard size">Standard size upto 1.5m</option>
                  <option value="Large, floor to ceiling">Large / floor to ceiling</option>
              
                
                </select> 

                

                <div className="icon-heading-container">
                    <FiSquare size={25} /> 
                    <h4 className="clean-h4">Want flyscreens cleaned?</h4>
                  </div>
            
                <select
                    className="input-contact-clean input-select-width"
                    name="rug_clean"
                    onChange={(e) => setSelectedRugs(e.target.value)}
                    
                  >
                    <option value="">Select number of screens?</option>
                    <option value="2 to 4 screens">2 to 4 screens $20</option>
                    <option value="4 to 6 screens">4 to 6 screens $30</option>
                    <option value="6 plus screens">6 plus screens $80</option>
                  </select>


                 

              <div className="book-clean">
                <h4 className="clean-h4">WHEN WOULD YOU LIKE US TO COME?</h4>
                <p className="clean-p">Choose the date and arrival window*<br /> that works for you. If you need a <br />last-minute appointment give<br /> us a call at 0433310964</p>
                <input
                  className="input-contact-clean"
                  type="datetime-local"
                  name="clean_date"
                  onChange={(e) => setSelectedDateTime(e.target.value)}
                  required
                />
              </div>
              <h4 className="clean-h4">Comments & Special Instructions</h4>
              <textarea className="input-contact-clean" type="text" name="Instructions" placeholder="Is there anything we should know? "  />
              
            </div>
          </div>
          
    
     
          <PriceSection
            selectedCleanliness={selectedCleanliness}
            selectedSteamClean={selectedSteamClean} 
            selectedHallWay={selectedHallWay} 
            selectedWindowClean={selectedWindowClean}
            selectedBalconyClean={selectedBalconyClean}
            selectedStairs={selectedStairs}
            selectedRugs={selectedRugs}
            handleDiscountClick={handleDiscountClick}
            setFinalPrice={setFinalPrice}
          />
         
          <div> {result ? <Result /> : null}</div>
          
          <input onClick={FormSubmission} className="qoute-button" type="submit" value="Book now pay later" />
          {/*Hidden imput for emailjs*/}
            <input type="hidden" name="selectedSteamClean" value={selectedSteamClean} />
            <input type="hidden" name="selectedHallWay" value={selectedHallWay} />
            <input type="hidden" name="selectedWindowClean" value={selectedWindowClean} />
            <input type="hidden" name="selectedBalconyClean" value={selectedBalconyClean} />
            <input type="hidden" name="selectedStairs" value={selectedStairs} />
            <input type="hidden" name="selectedRugs" value={selectedRugs} />
           <input type="hidden" name="final_price" value={finalPrice} />

           <div className="review-bottom steam-rating">
            <div className="review-content-bottom">
            <IoMdStar size={30} color="#ffcd00" />
            <IoMdStar size={30} color="#ffcd00" />
            <IoMdStar size={30} color="#ffcd00" />
            <IoMdStar size={30} color="#ffcd00" />
            <IoMdStar size={30} color="#ffcd00" />
            <img className="gimage" src={icon} alt="logo for customer rating"/>
           </div>

           </div>
       
        </form>
        
        
     
        <Profile/>
       
      </div> 
      <div className="foot"><Footer/></div> 
       
    </div>
  );
};

export default SteamForm;
