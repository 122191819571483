import React, { useState, useEffect } from 'react';
import './ImageSlides.css';
import Image1 from '../assets/backgroundThree.jpeg';
import { AiOutlineMinus } from 'react-icons/ai';
import { AiFillStar } from 'react-icons/ai';



const images = [Image1];

const ImageSlides = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setActiveIndex((activeIndex + 1) % images.length);
    }, 3000);
    return () => clearInterval(intervalId);
  }, [activeIndex]);

  const handleImageLoad = () => {
    setIsLoaded(true);
  };

  

  return (
    <div className={`slides-container ${isLoaded ? 'loaded' : ''}`}>
      <div className="image-slides">
        <div className="image-slides-wrapper" style={{ width: `${100 * images.length}%`, transform: `translateX(-${activeIndex * (100 / images.length)}%)` }}>
          {images.map((image, index) => (
            <div key={index} className="slide" style={{ display: 'inline-block', width: `${100 / images.length}%` }}>
              <img src={image} alt={`Slide ${index + 1}`} className="image-slide" onLoad={handleImageLoad} style={{ height: '80%' }} />
            </div>
          ))}
        </div>
      </div>
    <div className="heading-container">
      <div className="heading-content">
        <div className="heading-overlay">
          <h1 className="heading">We make Melbourne <br />Homes <span className="color__change">clean.</span><br/><AiOutlineMinus /></h1>
        </div>
      </div>
      <div className="circles-container">
        <div className="circle">
          <div className="circle-textmid">10% OFF<br/> FIRST CLEAN<br/><div><AiFillStar size={15}/><AiFillStar size={15}/><AiFillStar size={15}/><AiFillStar size={15}/><AiFillStar size={15}/></div></div>
        </div>
      </div>
   </div>  
    </div>
  );
};

export default ImageSlides;




