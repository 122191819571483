// ResponsiveInfo.js
import React from 'react';
import './WindowService.css';
import { TiStopwatch } from "react-icons/ti";
import { FaSuitcase } from "react-icons/fa";
import { FaHandshake } from "react-icons/fa";
import { FaHouseUser } from "react-icons/fa";

const WindowService = () => {
  return (
    <div className="responsive-info">
      <div className="info-row">
        <div className="info-item">
         
          <TiStopwatch size={35} color='#003953' />
          <h3 className='head-window'>Quick & easy estimates</h3>
          <p>
            We offer free estimates on all jobs. Call or text and we will provide you with a fair quote
            within 24hrs and schedule a time to visit your property to discuss our various cleaning
            options.
          </p>
        </div>
        <div className="info-item">
          <FaSuitcase size={35} color='#003953' />
          <h3 className='head-window'>We're insured</h3>
          <p>
            No need to worry! We are covered up to $1MM per occurrence and $2MM aggregate plus full
            auto and workers comp.
          </p>
        </div>
      </div>
      <div className="info-row">
        <div className="info-item">
          <FaHandshake size={35} color='#003953' />
          <h3 className='head-window'>We're reliable</h3>
          <p>
            We understand that time is one of the most valuable assets in life. Instead of a vague
            time frame, we will provide a fixed start and end time to ensure efficiency. If you feel
            dissatisfied with our service at any point we will return to the job to personally make
            things right.
          </p>
        </div>
        <div className="info-item">
          <FaHouseUser size={35} color='#003953' />
          <h3 className='head-window'>Respect your property</h3>
          <p>
            The “leave it better than you found it” principle is what we live by. The finer details
            are most important to us such as covering shoes when we enter your home, wiping all
            window sills, as well as mindfulness when it comes placing ladders and stools.
          </p>
        </div>
      </div>
    </div>
  );
};

export default WindowService;
