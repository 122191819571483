import { Helmet } from "react-helmet"
import "./BookingClean.css";
import HeaderSteam from "../components/Header";
import Footer from "../components/Footer";
import Profile from "../components/Profile";
import ServiceInfoSection from "../components/ServiceInfoSectionHome";
import { IoMdStar } from "react-icons/io";
import icon from "../assets/iconG.png"
import { Link } from "react-router-dom";
import WorkSlidesRevGen from "../components/WorkSlidesRevGen";
import ReviewSection from "../components/ReviewSection";
import FAQ from "../components/FaqGeneral";
import Location from "../components/LocationGeneral";
import GeneralCleanTop from "../components/GeneralCleanTop";
import GeneralCleanTwo from "../components/GeneralCleanTwo";
import ServiceInfoSectionGeneral from "../components/ServiceInfoSectionGeneral";
import GeneralCleanThree from "../components/GeneralCleanThree";
import GeneralCleaningPrices from "../components/GeneralCleaningPrices";
import GernalCleanFour from "../components/GernalCleanFour";
import Tiktok from "../components/Tiktok";
import WindowReview from "../components/WindowReview"
import WindowReviewTwo from "../components/WindowReviewTwo";



const GeneralCleaningMelbourne = () => {


  return (
    <div className="main-steam-book">
    <Helmet>
          <meta name="description" content="We offer general and end of lease cleaning service, from carpet steam cleaning through to cleaning."/>
          <script async src="https://www.googletagmanager.com/gtag/js?id=AW-11025934865"></script>
        </Helmet>
            <HeaderSteam/>
            <GeneralCleanTop/>
       
            <div className="rev-sec">
            <ReviewSection/>
            </div>

            <div className="steam-booking-homepage mar-rev-steam">
            <Link style={{textDecoration: 'none', color: 'inherit', }}  to="/General-Cleaning-Melbourne-quote"><button className='p-description end-lease-link'>LIVE BOOKING AND PRICE</button></Link>
            </div>
             <WindowReview/>
            <WorkSlidesRevGen/>
            <GeneralCleanTwo/>
            <div className="tik-mar"></div>
            <Tiktok/>
           
           
            <ServiceInfoSectionGeneral/>

          
          
            
            <GeneralCleanThree/>
           
            <div className="review-review-steam">
            

             
           <WindowReviewTwo/>
           <div className="lit-bit-mar"></div>

            <GernalCleanFour/>

            <FAQ/>
            
            



            <div className='info-steam-sec'>
            <ServiceInfoSection/>
            </div>

            <div className="steam-booking-homepage mar-buttom-steam">
            <Link style={{textDecoration: 'none', color: 'inherit', }}  to="/General-Cleaning-Melbourne-quote"><button className='p-description end-lease-link'>LIVE BOOKING AND PRICE</button></Link>
            </div>

            

            <div className="review-bottom steam-rating rate-mar-steam">
            <div className="review-content-bottom">
            <IoMdStar size={30} color="#ffcd00" />
            <IoMdStar size={30} color="#ffcd00" />
            <IoMdStar size={30} color="#ffcd00" />
            <IoMdStar size={30} color="#ffcd00" />
            <IoMdStar size={30} color="#ffcd00" />
            <img className="gimage" src={icon} alt="logo for customer rating"/>
           </div>

           </div>
           <Location/>

           

           
            </div>
            <Profile/>
            <div className="foot"><Footer/></div> 
       
    </div>
  );
};

export default GeneralCleaningMelbourne;
