import React, { useState, useEffect } from 'react';
import './Header.css';
import { Link } from 'react-router-dom';
import logo from "../assets/logo.png";
import { FaFacebookF, FaPhone } from 'react-icons/fa';
import { AiOutlineInstagram } from 'react-icons/ai';
import { ImPinterest2 } from 'react-icons/im';
import ContactUs from './ContactUs';
import ReactGA from 'react-ga';




const Header = () => {
  const phoneNumber = '0433310964'; // Replace this with the desired phone number


  const handleCallClick = () => {
    // Call the function to track the phone call conversion
    gtag_report_conversion();
    // Open the phone dialer with the provided phone number
    window.open(`tel:${phoneNumber}`);
  };

   // Define the Google Ads conversion tracking function
  const gtag_report_conversion = () => {
    window.gtag('event', 'conversion', {
      'send_to': 'AW-11025934865/CvyMCNXEjJkZEJHUyYkp'
    });
  };

  const initialTime = 3 * 60 * 60; // 3 hours in seconds
  const [remainingTime, setRemainingTime] = useState(initialTime);

  useEffect(() => {
    const interval = setInterval(() => {
      setRemainingTime((prevTime) => prevTime - 1);
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const formatTime = (timeInSeconds) => {
    const hours = Math.floor(timeInSeconds / 3600);
    const minutes = Math.floor((timeInSeconds % 3600) / 60);
    const seconds = timeInSeconds % 60;
    
    return <p className="ends-header"> {hours} hours : {minutes} minutes : {seconds} seconds</p>
}

  const FormSubmission = () => {
        ReactGA.event({
            category: 'Call button',
            
          });
        
    }




  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleMenuClick = () => {
    setIsMenuOpen(!isMenuOpen);
  }

  const [showPopupOnClick, setShowPopupOnClick] = useState(false);

  const popupStyleButton = {
    position: 'fixed',
    top: '50%',
    left: '50%',
    height:'100%',
    transform: showPopupOnClick ? 'translate(-50%, -50%)' : 'translate(-50%, -150%)',
    backgroundColor: 'black',
    display: showPopupOnClick ? 'block' : 'none',
    zIndex: 999,
  };

  const handleClose = () => {
    setShowPopupOnClick(false);
  };


  
  

  return (

    <div>
    <header className="header">
    <div className="popup" style={popupStyleButton}>

        <button className="close-button" onClick={handleClose}>
            X
          </button>
          <ContactUs/>
        </div>
    
      <Link style={{textDecoration: 'none', color: 'inherit', }}  to="/"><img className="logo header-logo" src={logo} alt="logo"/></Link>
      
      <div className="icons-container">
      <a className="social-none" style={{textDecoration: 'none', color: 'inherit', }}  href="https://www.facebook.com/profile.php?id=100091332464688"><FaFacebookF /></a>
      <a className="social-none" style={{textDecoration: 'none', color: 'inherit', }}  href="https://www.instagram.com/"><AiOutlineInstagram /></a>
      <a className="social-none" style={{textDecoration: 'none', color: 'inherit', }}  href="https://www.pinterest.com.au/"><ImPinterest2 /></a>
     
      </div>
      <nav className={`nav ${isMenuOpen ? "open" : ""}`}>
        <ul>
          <Link style={{textDecoration: 'none', color: 'inherit', }}><li className="nav__options" onClick={() => setShowPopupOnClick(true)}>Contact</li></Link>
          <Link style={{textDecoration: 'none', color: 'inherit', }}  to="/Commercial-ceaning"><li className="nav__options">Commercial</li></Link>
          <Link style={{textDecoration: 'none', color: 'inherit', }}  to="/Turn-over-clean"><li className="nav__options">Airbnb clean</li></Link>
          <Link style={{textDecoration: 'none', color: 'inherit', }}  to="/end-of-lease-cleaning-melbourne"><li className="nav__options">Bond Clean</li></Link>
          <Link style={{textDecoration: 'none', color: 'inherit', }}  to="/WindowCleaning"><li className="nav__options">Window Clean</li></Link>
       
          

        </ul>
       
      </nav>
      
       <button className="nav__options call-option-nav"  style={{textDecoration: 'none', color: 'inherit', }} onClick={handleCallClick}><FaPhone /> Call</button>
      <button className={`menu-button ${isMenuOpen ? "open" : ""}`} onClick={handleMenuClick}>
        <span className="line"></span>
        <span className="line"></span>
        <span className="line"></span>
      </button>
      
    </header>

        {/*count timer*/}
        <div>
          <div className="offer"><h3>10% off cleaning – ENDS SOON!</h3>
          {formatTime(remainingTime)}
          </div>
        </div>

    </div>
  );
}

export default Header;

