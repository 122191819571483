import React from 'react';
import extWindow from '../assets/Window.png'; // Import image1
import gutter from '../assets/Gutter.png'; // Import image2
import dustHigh from '../assets/Dusthigh.png'; // Import image1
import houseWash from '../assets/HouseWashing.png'
import windowInside from '../assets/insidewindow.png'; // Import image1
import lightFitting from '../assets/lightfiting.png'; // Import image2
import imageSolar from '../assets/SolarPannelClean.png'; // Import image2
import glassRail from '../assets/balconyGlass.png'; // Import image2
import flyScreen from '../assets/flyScreen.png'; // Import image2
import './serviceInfoSection.css';

const ServiceInfoSection = () => {
  const images = [
    { src: extWindow, alt: 'Window cleaner', title: 'Exterior window cleaning', description: 'Exterior window cleaning is our specialty. We take pride in keeping your windows spotless and looking their absolute best. Don’t forget to ask about our recurring cleaning plans to maintain that crystal-clear view year-round!' },
    { src: gutter, alt: 'Cleaning gutters', title: 'Gutter Cleaning', description: 'In Melbourne, its advised to clean your gutters twice a year to ensure proper water flow and keep your roof in good condition.' },
    { src: dustHigh, alt: 'Dusting high area', title: 'High Dusting', description: 'Tired of cobwebs? Let us tackle those hard-to-reach, dusty spots that might be impacting your air quality. From air ducts to ceiling fans and high beams, we’ll make sure every corner is spotless.' },
    { src: houseWash, alt: 'Full house wash', title: 'House Washing', description: 'Erase years of pollen, mold, and rust buildup in no time! Revitalize your home’s appearance by removing imperfections from your siding and restoring your paint job to its original brilliance.' },
    { src: windowInside, alt: 'Inside window cleaning', title: 'Interior Windows', description: 'Sometimes cleaning the exterior isn’t enough—the real problem is often inside. We’ll leave your interior windows spotless, all without a single drop of water out of place.' },
    { src: lightFitting, alt: 'Cleaning light fittings', title: 'Light Fixture', description: 'Brighten your home with a meticulous cleaning of every pane on your lanterns and sconces, both inside and out.' },
    { src: imageSolar, alt: 'Cleaning solar panel', title: 'Solar Panels', description: 'Dirty solar panels can significantly reduce energy absorption, potentially lowering efficiency by up to 25%.' },
    { src: glassRail, alt: 'Cleaning balcony window', title: 'Glass Railing', description: 'Improve your balcony views safely and efficiently with our detailed, double-sided deep clean!' },
    { src: flyScreen, alt: 'Fly screen cleaning', title: 'Screen Cleans', description: 'Your screens might be the hidden culprit behind your cleaning woes. We’ll gladly remove, clean, and reinstall them to keep everything looking and functioning its best.' },
    // Add more images here
  ];

  return (
    <div className="service-info-section">
      {images.map((image, index) => (
        <div key={index} className="service-item">
          <img src={image.src} alt={image.alt} />
          <h3>{image.title}</h3>
          <p>{image.description}</p>
        </div>
      ))}
    </div>
  );
};

export default ServiceInfoSection;
